"use strict";

$(function () {
  $("#filter-menu-button").on("click", function (e) {
    e.preventDefault();
    $(".drop-down-container").removeClass("filter-menu-selected");
    $(".drop-down-container").toggleClass("filter-menu-open");
  });
  $(".filter-button").on("click", function (e) {
    $(".filter-option").removeClass("active");
    $("body").removeClass("noscroll");
    $(this).addClass("active");
    var id = $(this).data("cat");
    console.log(id); 

    var htmlString = $(this).html();
    $("#project-title-context").text(htmlString);
    $(".drop-down-container").removeClass("filter-menu-open");
    $(".drop-down-container").addClass("filter-menu-selected");
  });
  $("#all-projects-button").on("click", function (e) {
    e.preventDefault();
    $(".filter-option").removeClass("active");
    $("body").removeClass("noscroll");
    $(this).addClass("active");
    var htmlString = $(this).html();
    $("#project-title-context").text(htmlString);
    $(".cat-item").show();
    $(".drop-down-container").removeClass("filter-menu-open");
    $(".drop-down-container").addClass("filter-menu-selected");
    msnry.layout();
  });
  $("#drop-down-open").on("click", function (e) {
    e.preventDefault();
  });
});